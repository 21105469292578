<template>
    <div class="order">
        <div class="search">
            <div class="a-inline-block">
                <label for="">订单编号</label><a-input v-model="osn" size="large" placeholder="请输入订单编号" />
            </div>
            <div class="a-inline-block">
                <label for="">客户名称</label><a-input v-model="name" size="large" placeholder="请输入客户名称" />
            </div>
            <div class="a-inline-block">
                <label for="">跟进人</label><a-input v-model="staff_uid2" size="large" @click="chooseStaff" placeholder="请选择跟进人" />
            </div>
            <staff :staffFlag="staffFlag"  @updateStaffFlag="updateStaffFlag"></staff>
            <div class="a-inline-block">
                <label for="">订单状态</label>
                <a-select size="large" v-model="status">
                    <a-select-option :value="null">全部</a-select-option>
                    <a-select-option v-for="(item,index) in orderStatus" :key="item" :value="index">{{item}}</a-select-option>
                </a-select>
            </div>
            <div class="a-inline-block2">
                <label class="info" for="">签订时间</label>
                <a-range-picker v-model="date" size="large" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" />
            </div>
            <div class="a-inline-block">
                <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                <a-button size="large" @click="reset">重置</a-button>
            </div>
            
            <div style="text-align:right;">
                <a-button style="width:auto;" size="large" type="primary" @click="delAll">批量删除</a-button>
            </div>
        </div>
        <a-table :row-selection="rowSelection" :rowKey='record=>record.id' :columns="columns" :data-source="data" class="table" id="myTable" :pagination="pagination" :scroll="{ x: true}">
            <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Action" slot-scope="text,record">
                <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a>
                <a class="action" href="javascript:;" @click="editOrder(record.id)" v-if="record.status != '已完成' && record.status != '关闭'">编辑</a>
                <a class="action" href="javascript:;" @click="finish(record.id)" v-if="record.status != '已完成' && record.status != '关闭'">完成</a>
                
                <a-popconfirm title="确定要关闭吗?" @confirm="() => off(record.id)"  v-if="record.status != '已完成' && record.status != '关闭'">
                    <a class="action del" href="javascript:;">关闭</a>
                </a-popconfirm>
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
                    <a class="action del" href="javascript:;">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
    </div>
</template>
<script>
import $ from "jquery";
import {requestXml,getDateTime} from '../../../assets/js/request';
import staff from '../../../components/staff.vue';
// var moment = require('moment');
export default {
  components: { staff },
    data() {
        return {
            // 订单编号
            osn: "",
            // 客户名称
            name: "",
            // 跟进人
            staff_uid: "",
            staff_uid2: "",
            staffFlag: false,

            // 开始时间
            start: "",
            // 结束时间
            end: "",
            // 签订时间
            date: [],
            status: null,
            orderStatus: ["关闭","新建","进行中","已完成"],
            columns:[
                { title: '序号', dataIndex: 'num', width: 60, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                { title: '订单编号', dataIndex: 'osn',width: 120},
                { title: '客户名称', dataIndex: 'name',width: 80},
                { title: '订单金额', dataIndex: 'amount',width: 80 },
                { title: '应收金额', dataIndex: 'invoice_amount',width: 80 },
                { title: '实收金额', dataIndex: 'payment_amount',width: 80 },
                { title: '退款金额', dataIndex: 'refund_amount',width: 80 },
                { title: '补签金额', dataIndex: 'replenish_amount',width: 80 },
                { title: '跟进人', dataIndex: 'staff_uid',width: 80 },
                { title: '状态', dataIndex: 'status',width: 100 },
                { title: '订单签订时间', dataIndex: 'sign_time',width: 120 },
                { title: '订单到期时间', dataIndex: 'end_time',width: 120  },
                { title: '创建时间', dataIndex: 'created',width: 120  },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 180,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            data:[],
            selectedRows:[],
            rowSelection:{
                    onSelect: (record, selected, selectedRows) => {
                        console.log(record, selected, selectedRows);
                        this.selectedRows=selectedRows;
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        console.log(selected, selectedRows, changeRows);
                        this.selectedRows=selectedRows;
                    },
                },

            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
			},
            selectedRowKeys: []
        };
    },
    mounted: function(){
        this.getOrder();
        this.getField();
        
    },
    methods: {
        // 跟进人
        chooseStaff() {
            this.staffFlag = true;
        },
        // 分配完 取消表格选中 清空客户 重新加载列表
        updateStaffFlag(data){
            console.log(data,"dasds")
            if(data != 1){
                this.staff_uid = data.userid;
                this.staff_uid2 = data.name;
            }
            this.staffFlag = false;
        },
        // 搜索
        onSearch(){
            this.pagination.current=1;
            if(this.date.length != 0){
                this.date[0]._d.setHours(0);
                this.date[0]._d.setMinutes(0);
                this.date[0]._d.setSeconds(0);

                this.date[1]._d.setHours(23);
                this.date[1]._d.setMinutes(59);
                this.date[1]._d.setSeconds(59);
                console.log(this.date)
                this.start = this.date[0]._d.getTime();
                this.end = this.date[1]._d.getTime();
            }else{
                this.start = "";
                this.end = "";
            }
            this.getOrder();
        },
        reset(){
            this.osn = "";
            this.name = "";
            this.date = [];
            this.start = "";
            this.end = "";
            this.status = null;
            this.staff_uid = "";
            this.staff_uid2 = null;
            this.getOrder();
        },
        getField() {
            requestXml("/scrm/Order/getField","POST",(res) => {
                for(let i=0;i<res.length;i++){
                    let obj = {};
                    obj.title = res[i].text;
                    obj.dataIndex = res[i].name;
                    obj.width = 100;

                    this.columns.splice(this.columns.length-1,0,obj);
                }
            })
            // console.log(this.columns,"this.columns")
        },
        getOrder() {
            requestXml("/Scrm/Order/getMyList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sign_time = getDateTime(res.list[i].sign_time);
                    res.list[i].end_time = getDateTime(res.list[i].end_time);
                    res.list[i].created = getDateTime(res.list[i].created);
                    
                    res.list[i].status = this.orderStatus[Number(res.list[i].status)];
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;


                var tr = document.createElement("tr");
                tr.setAttribute("id","mytr");
                tr.innerHTML = `<td style="width:70px">本页合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td>`+ res.sum.page.amount +`</td>
                <td>`+ res.sum.page.payment_amount +`</td>
                <td>`+ res.sum.page.refund_amount +`</td>
                <td>`+ res.sum.page.replenish_amount +`</td>`;

               
                var tr2 = document.createElement("tr");
                tr2.setAttribute("id","mytr2");
                tr2.innerHTML = `<td>全部合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td>`+ res.sum.all.amount +`</td>
                <td>`+ res.sum.all.payment_amount +`</td>
                <td>`+ res.sum.all.refund_amount +`</td>
                <td>`+ res.sum.all.replenish_amount +`</td>`;


                // if($("#mytr").length > 0) {
                    $("#mytr").remove();
                    $("#mytr2").remove();
                // }else{
                    this.$nextTick(
                        function(){
                            $("#myTable").find(".ant-table-body").find("table").find("tbody").append(tr);
                            $("#myTable").find(".ant-table-body").find("table").find("tbody").append(tr2);
                        }
                    )
                // }
                

            },{ "osn": this.osn,
                "name": this.name,
                "start": this.start,
                "end": this.end,
                "status": this.status,
                "staff_uid": this.staff_uid,
                "page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}
            })
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            // console.log(current)
            this.pagination.current = current;
            this.getOrder(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            // console.log(current, pageSize)
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getOrder(); //向后端发送请求
        },
        // 查看
        showDetail(id){
            this.$router.push({
                path: "/scrm_pc/order/orderDetail",
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        editOrder(id){
            this.$router.push({
                path: "/scrm_pc/order/orderAdd",
                query: {
                    id: id,
                }
            });
        },
        // 完成
        finish(id){
            requestXml("/scrm/Order/setDone","POST",(res) => {
                if(res == "success"){
                    this.getOrder();
                }
            },{"id": id})
        },
        // 关闭
        off(id){
            requestXml("/scrm/Order/setClose","POST",(res) => {
                if(res == "success"){
                    this.getOrder();
                }
            },{"id": id})
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Order/del","POST",(res) => {
                if(res == "success"){
                    this.getOrder();
                }
            },{"id": id})
        },
        delAll(){
               if(this.selectedRows.length<1){
                this.$message.info('请先选择');
                return false;
            }else{
                let idArr=[];
                let sel=this.selectedRows;
                for(let i=0;i<sel.length;i++){
                    idArr.push(sel[i].id);
                }
                this.onDelete(idArr);
            }
        },
    },
    
};
</script>
<style scoped>
    .order .search .ant-calendar-picker{
        width: 300px !important;
    }
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
</style>

